<template>
    <div>
        <div class="of-instrument-grid-title">{{name}}</div>
        <vxe-table 
            :data="data" 
            zebra
            size="mini"
            class="of-balance-grid common-grid">
            <vxe-table-column field="account" title="Account" min-width="65"></vxe-table-column>
            <vxe-table-column field="strategy"  title="Strategy" min-width="65" v-if="0"></vxe-table-column>
            <vxe-table-column field="balance" title="Position" width="125">
                <template v-slot="{ row }">
                    {{row.balance | numFormat('0,0.[00]')}}
                    <div v-if="row.blocked_balance" class="of-instrument-blocked">
                        <i class="el-icon-lock"></i> {{row.blocked_balance | numFormat('0,0.[00]')}}
                    </div>
                </template>
            </vxe-table-column>
        </vxe-table>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true,
        },
        data: {
            type: Array,
            required: true
        }
    }
}
</script>
